import axiosInstance from '../components/common/axiosInstance';

/**
 * 국가 목록
 *
 * @returns {Promise}
 */
const getList = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/country')
      .then(res => resolve(res.data?.items))
      .catch(reject);
  });

const CountryService = { getList };

export default CountryService;
