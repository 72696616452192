import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import _ from 'lodash';

import CountryService from '../services/CountryService';

const store = persist(
  (set, get) => ({
    country: '',
    countryList: [],
    getCountryList: () => get().countryList,
    setCountry: country => set({ country }),
    setCountryList: countryList => set({ countryList }),
    fetchCountryList: async () => {
      if (_.isEmpty(get().countryList)) {
        const _res = await CountryService.getList();
        if (!_.isEmpty(_res)) set({ countryList: _res });
      }
    },
  }),
  {
    name: 'country',
  }
);

const useCountry = create(process.env.NODE_ENV !== 'production' ? devtools(store) : store);

useCountry.getState().fetchCountryList();

export default useCountry;
