import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default function ToastError() {
  const theme = useContext(ThemeContext);
  return (
    <svg width='33' height='28' viewBox='0 0 33 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 0.5L0 27.5H33L16.5 0.5ZM16.5 3.71657L2.96568 25.8636H30.0343L16.5 3.71657ZM15.333 9.5H17.6663V19.6111H15.333V9.5ZM15.333 21.168H17.6663V23.5013H15.333V21.168Z'
        fill={theme.palette.toast.error.icon}
      />
    </svg>
  );
}
