import axiosInstance from '../components/common/axiosInstance';

/**
 * 프로필 정보 가져오기
 * @param {*=} config
 *
 * @returns {Promise}
 */
const getProfile = config =>
  new Promise((resolve, reject) => {
    axiosInstance.get('/profile', config).then(resolve).catch(reject);
  });

/**
 * 프로필 정보 수정
 * @param {Object} params
 * @param {string} params.name
 * @param {string} params.software
 * @param {string} params.service
 * @param {string} params.equipment
 * @param {*=} header - 헤더
 *
 * @returns {Promise}
 */
const updateProfile = params =>
  new Promise((resolve, reject) => {
    axiosInstance.patch('/profile', params).then(resolve).catch(reject);
  });

/**
 * 프로필 > 비밀번호 변경
 * @param {Object} params
 * @param {string} params.pwd
 * @param {string} params.changePwd
 * @param {*=} header - 헤더
 *
 * @returns {Promise}
 */
const updatePassword = params =>
  new Promise((resolve, reject) => {
    axiosInstance.patch('/profile/reset/password', params).then(resolve).catch(reject);
  });

/**
 * 연락처 정보 수정
 * @param {Object} params
 * @param {string} params.detail_address
 * @param {string} params.city
 * @param {string} params.country
 * @param {string} params.zipcode
 * @param {string} params.phone
 * @param {*=} header - 헤더
 *
 * @returns {Promise}
 */
const updateContact = params =>
  new Promise((resolve, reject) => {
    axiosInstance.patch('/address', params).then(resolve).catch(reject);
  });

/**
 * 프로필 정보 수정
 * @param {*} params
 * @param {*=} header - 헤더
 *
 * @returns {Promise}
 */
const updateProfileImage = (params, header) =>
  new Promise((resolve, reject) => {
    axiosInstance.patch('/profile/image', params, header).then(resolve).catch(reject);
  });

/**
 * 대표 영상 업데이트
 * @param {Object} params
 * @param {string} cut1
 * @param {string} cut2
 * @param {string} cut3
 * @param {*=} header - 헤더
 *
 * @returns {Promise}
 */
const updateProfileCut = (params, header) =>
  new Promise((resolve, reject) => {
    axiosInstance.patch('/profile/cuts', params, header).then(resolve).catch(reject);
  });

const ProfileService = {
  getProfile,
  updateProfile,
  updatePassword,
  updateContact,
  updateProfileImage,
  updateProfileCut,
};

export default ProfileService;
