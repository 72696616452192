import React, { forwardRef, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { fontFamily, variants } from '../typography/Typography';
import { LayoutContext } from '../../../context/LayoutProvider';

const ButtonContainer = styled.button`
  ${fontFamily};
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  ${props =>
    props.size &&
    props.size === 'xs' &&
    css`
      width: 102px;
    `};
  ${props =>
    props.size &&
    props.size === 'sm' &&
    css`
      width: 286px;
    `};
  ${props =>
    props.size &&
    props.size === 'md' &&
    css`
      width: 384px;
    `};
  ${props =>
    props.size &&
    props.size === 'lg' &&
    css`
      width: 588px;
    `};
  ${props =>
    props.size &&
    props.size === 'xl' &&
    css`
      width: 1200px;
    `};
  ${props =>
    props.height
      ? css`
          height: ${props => `${props.height}px !important`};
          padding: ${props => `0 ${props.theme.spacing(4)}`};
        `
      : css`
          padding: ${props => `${props.theme.spacing(2)} ${props.theme.spacing(4)}`};
        `}
  /* padding: 12px, 16px, 12px, 16px; */
  user-select: none;
  border-radius: 10px;
  border-width: ${props => (props?.variant === 'outlined' ? '1px' : 0)};
  border-style: ${props => (props?.variant === 'outlined' ? 'solid' : 'none')};
  border-color: ${props => (props.color === 'secondary' ? props.theme.palette.secondary.main : props.theme.palette.primary.main)};
  background-color: ${props =>
    props?.variant === 'contained'
      ? props.color === 'secondary'
        ? props.theme.palette.secondary.main
        : props.theme.palette.primary.main
      : 'transparent'};
  color: ${props =>
    props?.variant === 'contained'
      ? props.color === 'secondary'
        ? props.theme.palette.primary.main
        : props.theme.palette.secondary.main
      : props.color === 'secondary'
      ? props.theme.palette.secondary.main
      : props.theme.palette.primary.main};
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
  transition: background-color 0.3s ease 0s;
  &:hover,
  &[aria-pressed='true'] {
    border-color: ${props =>
      !props?.disabled && (props.color === 'secondary' ? props.theme.palette.secondary.main : props.theme.palette.primary.main)};
    background-color: ${props =>
      !props?.disabled && (props.color === 'secondary' ? props.theme.palette.secondary.dark : props.theme.palette.primary.dark)};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      border-style: ${props => (props?.variant === 'outlined' ? 'solid' : 'none')};
      border-color: ${props => (props?.variant === 'outlined' ? props.theme.dim['40_white'] : 'none')};
      background-color: ${props => (props?.variant === 'outlined' ? props.theme.palette.transparent : props.theme.palette.disable)};
      color: ${props => (props?.variant === 'outlined' ? props.theme.dim['40_white'] : props.theme.palette.channelWhite)};
    `};
  ${props => props.labelStyle && variants[props.labelStyle]};
  ${props =>
    props.isMobile
      ? css`
          font-size: ${props => props.theme.fontSize['14']};
          height: 37px;
        `
      : css`
          font-size: ${props => props.theme.fontSize['20']};
          height: 54px;
        `}
`;

const Button = forwardRef((props, ref) => {
  const { children, variant, size, disabled, color, labelStyle, fullWidth, onClick, ...other } = props;
  const { isMobile } = useContext(LayoutContext);
  const buttonRef = useRef(null);

  return (
    <>
      <ButtonContainer
        type={'button'}
        fullWidth={fullWidth}
        size={size}
        variant={variant}
        disabled={disabled}
        color={color}
        onClick={onClick}
        labelStyle={labelStyle}
        isMobile={isMobile}
        ref={ref}
        {...other}
      >
        {children}
      </ButtonContainer>
    </>
  );
});

Button.defaultProps = {
  variant: 'contained',
  // size: 'md',
  color: 'primary',
  fullWidth: false,
  labelStyle: 'L1',
};

Button.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined']).isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  labelStyle: PropTypes.oneOf(['H1', 'H2', 'H3', 'T1', 'T2', 'T3', 'L1', 'L2', 'LI1', 'LI2', 'C1', 'C2', 'C3', 'C4']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Button;
