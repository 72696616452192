import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '../typography/Typography';
import ToastSuccess from '../icon/ToastSuccess';
import ToastWarning from '../icon/ToastWarning';
import ToastError from '../icon/ToastError';
import ToastInfo from '../icon/ToastInfo';

const Toast = ({ type, title, content }) => {
  const ToastIcon = useMemo(() => {
    switch (type) {
      case 'error':
        return <ToastError />;
      case 'warning':
        return <ToastWarning />;
      case 'success':
        return <ToastSuccess />;
      default:
        return <ToastInfo />;
    }
  }, [type]);

  return (
    <ToastContainer type={type}>
      <div>{ToastIcon}</div>
      <div className={'toast-message'}>
        <Typography variant={'L1'} className={'toast-title'}>
          {title}
        </Typography>
        <Typography variant={'C3'} className={'toast-content'}>
          {content}
        </Typography>
      </div>
    </ToastContainer>
  );
};

Toast.defaultProps = {
  type: 'info',
};

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Toast;

const ToastContainer = styled.div`
  width: 385px;
  min-height: 94px;
  background-color: ${({ theme, type }) => theme.palette.toast[type].background};
  border-color: ${({ theme, type }) => theme.palette.toast[type].border};
  border-width: 2px;
  border-style: solid;
  color: ${({ theme }) => theme.palette.white};
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(2, 6)};
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 20px 0px #00000040;
  user-select: none;
  & > .toast-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    & > p {
      word-break: break-word;
      white-space: pre-wrap;
    }
    & .toast-title {
      color: #fff;
    }
    & .toast-content {
      color: ${({ theme }) => theme.dim['70_white']};
      font-weight: 400 !important;
    }
  }
`;

export const ToastBox = styled.div.attrs(props => ({ role: 'presentation' }))`
  position: fixed;
  top: ${({ theme }) => theme.spacing(30)};
  right: ${({ theme }) => theme.spacing(8)};
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
`;
