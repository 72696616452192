import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

const Close = ({ color = 'white', width, height, ...other }) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width={`${width}`} height={`${height}`} viewBox={`0 0 22 22`} fill='none' xmlns='http://www.w3.org/2000/svg' {...other}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.292894 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L11 9.58579L20.2929 0.292894C20.6834 -0.0976303 21.3166 -0.0976301 21.7071 0.292894C22.0976 0.683418 22.0976 1.31658 21.7071 1.70711L12.4142 11L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L11 12.4142L1.70711 21.7071C1.31658 22.0976 0.683417 22.0976 0.292893 21.7071C-0.0976311 21.3166 -0.0976311 20.6834 0.292893 20.2929L9.58579 11L0.292894 1.70711C-0.0976303 1.31658 -0.0976301 0.683417 0.292894 0.292893Z'
        fill={theme.palette[color]}
      />
    </svg>
  );
};

Close.defaultProps = {
  color: 'white',
  width: 22,
  height: 22,
};

Close.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Close;
