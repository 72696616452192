import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import GlobalFonts from './assets/GlobalFonts';
import './assets/css/index.css';
import './assets/scss/index.scss';
import theme from './assets/theme';
import LayoutProvider from './context/LayoutProvider';
import PopupProvider from './context/PopupContext';
import ToastProvider from './context/ToastContext';
import QueryProvider from './queries/QueryProvider';

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryProvider>
    <BrowserRouter>
      <LayoutProvider>
        <CookiesProvider>
          <GlobalFonts />
          <ThemeProvider theme={theme}>
            <PopupProvider>
              <ToastProvider>
                <App />
              </ToastProvider>
            </PopupProvider>
          </ThemeProvider>
        </CookiesProvider>
      </LayoutProvider>
    </BrowserRouter>
  </QueryProvider>
);
