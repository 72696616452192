import useLanguage from '../store/language';

export const TOKEN_NAME = '_user_';

export const CDN_URL = 'https://cdn.keycutstock.com';

export const HOMEPAGE_URL = 'https://www.keycutstock.com';
export const API_URL =
  process.env.NODE_ENV === 'production' ? 'https://artist-api.keycutstock.com/api' : 'https://artist-dev.keycutstock.com/api';

export const THUMBNAIL_IMAGE = clipId => `https://image-preview.keycutstock.com/prod/thumb/${clipId.file_path}/${clipId.img_name}.webp`;

export const THUMBNAIL_PREFIX = (clipId, file_path) => {
  let first = file_path?.split('/')[0];
  let second = file_path?.split('/')[1];

  return `https://preview.keycutstock.com/jpg/${first}/${second}/${clipId}.jpg`;
};

export const PREVIEW_PREFIX = (clipId, file_path) => {
  let first = file_path?.split('/')[0];
  let second = file_path?.split('/')[1];

  return `https://preview.keycutstock.com/mp4/${first}/${second}/${clipId}.mp4`;
};

export const SNS_URL = [
  {
    name: 'facebook',
    image: '/assets/image/icon/facebook.svg',
    url: 'https://www.facebook.com/Keycutstock-101189028305060',
  },
  {
    name: 'instagram',
    image: '/assets/image/icon/instagram.svg',
    url: 'https://www.instagram.com/keycutstock',
  },
  {
    name: 'vimeo',
    image: '/assets/image/icon/vimeo.svg',
    url: 'https://vimeo.com/user108700432',
  },
  {
    name: 'youtube',
    image: '/assets/image/icon/youtube.svg',
    url: 'https://www.youtube.com/channel/UCi1qthKDKfx36wL6ziwXyIg',
  },
];

export const ORDER_BY = [
  {
    value: 'newest',
    label_en: 'newest',
    label_ko: '최신순',
    lang_code: 'porffolio511_label_2',
  },
  {
    value: 'popularity',
    label_en: 'popular',
    label_ko: '인기순',
    lang_code: 'porffolio511_label_1',
  },
  {
    value: 'oldest',
    label_en: 'oldest',
    label_ko: '오래된순',
    lang_code: 'porffolio511_label_3',
  },
];

export const PORTFOLIO_ORDER_BY = [
  {
    value: 'newest',
    label_en: 'Newest',
    label_ko: '최신순',
    lang_code: 'porffolio511_label_2',
  },
  {
    value: 'popularity',
    label_en: 'Popular',
    label_ko: '인기순',
    lang_code: 'porffolio511_label_1',
  },
];

export const SALE_LIST_TYPE = [
  {
    value: 'all',
    label_en: 'All',
    label_ko: '전체',
  },
  {
    value: 'video',
    label_en: 'Video',
    label_ko: '비디오',
  },
  {
    value: 'image',
    label_en: 'Image',
    label_ko: '이미지',
  },
];

export const SUBMIT_ORDER_BY = [
  {
    value: 'new',
    label_en: 'Newest',
    label_ko: '최신순',
    lang_code: 'submit621_label_1',
  },
  // {
  //   value: 'job_comp',
  //   label_en: 'completed',
  //   label_ko: '작업완료순',
  //   lang_code: 'submit621_label_2',
  // },
  {
    value: 'old',
    label_en: 'Oldest',
    label_ko: '오래된순',
    lang_code: 'submit621_label_3',
  },
];

export const PAYEE_TYPE = [
  {
    lang_code: 'mypage_payeepayment_payeeinfo_radio_personal_label_1',
    label_ko: '개인',
    label_en: 'Individual',
    value: 0,
  },
  {
    lang_code: 'mypage_payeepayment_payeeinfo_radio_company_label_2',
    label_ko: '개인/법인사업자',
    label_en: 'Personal/Corporate Business Owner',
    value: 1,
  },
];

export const PAYOUT_METHODS = [
  {
    label: 'Royalty',
    value: '0',
  },
  {
    label: 'MG',
    value: '1',
  },
];

export const PAYMENT_METHODS = [
  {
    lang_code: 'mypage_payeepayment_paymentinfo_paypal_title_1',
    label_en: 'PAYPAL',
    label_ko: 'PAYPAL',
    value: 1,
  },
  {
    lang_code: 'mypage_payeepayment_paymentinfo_payoneer_title_1',
    label_en: 'PAYONEER',
    label_ko: 'PAYONEER',
    value: 2,
  },
  {
    lang_code: 'paymentinfo22_label_3',
    label_en: 'Bank Transfer',
    label_ko: 'Bank Transfer',
    value: 0,
  },
];

export const SETTLEMENT_METHODS = [
  {
    lang_code: 'paymentinfo22_buttonlabel_3',
    label_en: 'Payment when request',
    label_ko: '요청 시 지급',
    value: 'N',
  },
  {
    lang_code: 'paymentinfo22_buttonlabel_4',
    label_en: 'Automatically payment',
    label_ko: '자동지급',
    value: 'Y',
  },
];

export const MINIMUM_SETTLEMENT_AMOUNT = [
  {
    lang_code: 'paymentinfo22_buttonlabel_5',
    label_en: '$100+',
    label_ko: '$100+',
    value: 100,
  },
  {
    lang_code: 'paymentinfo22_buttonlabel_6',
    label_en: '$200+',
    label_ko: '$200+',
    value: 200,
  },
  {
    lang_code: 'paymentinfo22_buttonlabel_7',
    label_en: '$300+',
    label_ko: '$300+',
    value: 300,
  },
  {
    lang_code: 'paymentinfo22_buttonlabel_8',
    label_en: '$400+',
    label_ko: '$400+',
    value: 400,
  },
  {
    lang_code: 'paymentinfo22_buttonlabel_9',
    label_en: '$500+',
    label_ko: '$500+',
    value: 500,
  },
];

export const RELEASE_TYPE = [
  {
    value: 'all',
    label_en: 'Total Release',
    label_ko: '허가서 전체',
    lang_code: 'releaseupload621modal2_placeholder_1',
  },
  {
    value: 'M',
    label_en: 'Model',
    label_ko: '모델',
    lang_code: 'releaseupload621modal2_taglabel_1',
  },
  {
    value: 'P',
    label_en: 'Property',
    label_ko: '자산',
    lang_code: 'releaseupload621modal2_taglabel_2',
  },
];

export const HEADER_LANGUAGE = () => {
  const _language = useLanguage.getState().getLanguage();
  return { 'Accept-Language': _language === 'ko' ? 'ko_KR' : 'en_US' };
};
