import axiosInstance from '../components/common/axiosInstance';

/**
 * 언어 목록
 *
 * @returns {Promise}
 */
const getList = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/lang')
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });

const LanguageService = { getList };

export default LanguageService;
