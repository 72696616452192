import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { checkIsMobile, getToken, removeToken } from '../utils';

import useUser from '../store/user';

export const LayoutContext = createContext();
// export const LayoutContext = createContext({
//   isMobile: false,
//   isLoggedIn: false,
// });

const LayoutProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { initUser } = useUser(
    useCallback(state => ({
      initUser: state.initUser,
    }))
  );

  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [isLoggedIn, setIsLoggedIn] = useState(getToken() ? true : false);

  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    if (checkIsMobile()) {
      if (/(inputpw|verify|complete)/gi.test(location.pathname)) {
        setIsMobile(true);
        meta.content =
          'width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover';
        document.getElementById('root').style.overflow = 'none';
      } else {
        setIsMobile(false);
        meta.content = 'width=1920, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes, shrink-to-fit=no';
      }
    } else {
      meta.content = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes, shrink-to-fit=no';
      if (!/(viewer)/gi.test(location.pathname)) {
        document.getElementById('root').style.minWidth = '1280px';
        // document.getElementById('root').style.overflowX = 'auto';
        // document.getElementById('root').style.maxWidth = '1920px';
        // document.getElementById('root').style.minWidth = '1920px';
      }
      // if (!/(terms)/gi.test(location.pathname)) {
      // document.getElementById('root').style.minHeight = '720px';
      // document.getElementById('root').style.maxHeight = '1080px';
      // document.getElementById('root').style.minHeight = '1080px';
      // document.getElementById('root').style.overflowY = 'auto';
      // }
      setIsMobile(false);
    }
    // document.getElementById('root').style.overflowX = 'hidden';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }, [navigator.userAgent]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (_.isUndefined(getToken())) {
      setIsLoggedIn(false);
      if (!/(sign|verify|complete|migration|pw)/gi.test(location.pathname)) {
        // localStorage.removeItem('user');
        // initUser();
        // removeToken();
        // navigate('/', { replace: true });
      }
    } else {
      setIsLoggedIn(true);
    }
  }, [location.pathname]);

  return <LayoutContext.Provider value={{ isMobile, isLoggedIn }}>{children}</LayoutContext.Provider>;
};

export default LayoutProvider;
