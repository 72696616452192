const pixelToRem = size => `${size / 16}rem`;

const palette = {
  primaryBlue: '#1e7dff',
  secondaryBlue: '#005fff',
  alert: '#ff4242',
  disable: '#888',
  black: '#141414',
  darkgray: '#292929',
  darkgray2: '#484848',
  darkgray3: '#6b6b6b',
  gray: '#939393',
  gray2: '#b8b8b8',
  lightgray: '#dadada',
  lightgray2: '#eee',
  white: '#fff',
  channelWhite: '#f6f6f6',
  transparent: 'transparent',
  yellow: '#ffc907',
  red: '#ff003f',
  warning: '#ff7a00',
  complete: '#00ff19',
  primary: {
    main: '#1e7dff',
    dark: '#0060b8',
  },
  secondary: {
    main: '#fff',
    dark: 'rgba(255, 255, 255, 0.2)',
  },
  warning: {
    main: '#dfc900',
  },
  error: {
    main: '#ff4747',
  },
  videoType: {
    commercial: '#ff42bf',
    editorial: '#37b7ff',
  },
  metadataStatus: {
    complete: {
      border: '#00b212',
      background: '#00ff19',
    },
    warning: {
      border: '#c76002',
      background: '#ff7a00',
    },
  },
  input: {
    standard: '#fff',
    label: '#292929',
    focus: '#1e7dff',
    error: '#ff4747',
  },
  modal: {
    background: '#555',
    inner: '#444',
  },
  card: {
    background: '#555',
  },
  toast: {
    success: {
      icon: '#5a8965',
      background: '#242d26',
      border: '#3a5140',
    },
    info: {
      icon: '#418a9d',
      background: '#17343c',
      border: '#1e4b57',
    },
    warning: {
      icon: '#a4793d',
      background: '#3a2d1a',
      border: '#553c18',
    },
    error: {
      icon: '#9f4646',
      background: '#351a19',
      border: '#532121',
    },
  },
  chip: {
    primary: '#dd8a28',
    secondary: '#118fea',
  },
};

const fontWeight = {
  light: 300,
  normal: 400,
  bold: 700,
};

const fontSize = {
  ['100']: '6.25em',
  ['80']: '5em',
  ['70']: '4.375em',
  ['50']: '3.125em',
  ['48']: '3em',
  ['44']: '2.75em',
  ['42']: '2.625em',
  ['40']: '2.5em',
  ['36']: '2.25em',
  ['32']: '2em',
  ['30']: '1.875em',
  ['28']: '1.75em',
  ['26']: '1.625em',
  ['24']: '1.5em',
  ['22']: '1.375em',
  ['20']: '1.25em',
  ['18']: '1.125em',
  ['16']: '1em',
  ['15']: '0.9375em',
  ['14']: '0.875em',
  ['13']: '0.8125em',
  ['12']: '0.75em',
  ['11']: '0.6875em',
  ['10']: '0.625em',
};

const dim = {
  ['70']: 'rgba(0, 0, 0, 0.7)',
  ['50']: 'rgba(0, 0, 0, 0.5)',
  ['30']: 'rgba(0, 0, 0, 0.3)',
  ['70_darkgray']: 'rgba(42, 42, 42, 0.7)',
  ['70_white']: 'rgba(255, 255, 255, 0.7)',
  ['60_white']: 'rgba(255, 255, 255, 0.6)',
  ['50_white']: 'rgba(255, 255, 255, 0.5)',
  ['40_white']: 'rgba(255, 255, 255, 0.4)',
  ['30_white']: 'rgba(255, 255, 255, 0.3)',
  ['20_white']: 'rgba(255, 255, 255, 0.2)',
  ['6_white']: 'rgba(255, 255, 255, 0.06)',
};

const spacing = (factor1, factor2, factor3, factor4) => {
  if ((!!factor2 || factor2 === 0) && (!!factor3 || factor3 === 0) && (!!factor4 || factor4 === 0))
    return `${0.25 * factor1}rem ${0.25 * factor2}rem ${0.25 * factor3}rem ${0.25 * factor4}rem`;
  else if ((!!factor2 || factor2 === 0) && (!!factor3 || factor3 === 0))
    return `${0.25 * factor1}rem ${0.25 * factor2}rem ${0.25 * factor3}rem`;
  else if (!!factor2 || factor2 === 0) return `${0.25 * factor1}rem ${0.25 * factor2}rem`;
  else return `${0.25 * factor1}rem`;
};

const deviceSize = {
  mobile: '500px',
  tablet: '768px',
  desktop: '1024px',
};

const device = {
  mobile: `screen and (0px <= width <= ${deviceSize.mobile})`,
  tablet: `screen and (${deviceSize.mobile} < width <= ${deviceSize.tablet})`,
  desktop: `screen and (min-width: ${deviceSize.desktop})`,
};

const breakpoints = {
  xs: 600,
  sm: 900,
  md: 1280, // 720
  lg: 1600, // 900
  xl: 1920, // 1080
  xxl: 1920, // 1080
};

const theme = {
  palette,
  fontWeight,
  fontSize,
  dim,
  spacing,
  deviceSize,
  device,
  breakpoints,
};

export default theme;
