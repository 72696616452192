import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DOMPurify from 'dompurify';

export const fontFamily = css`
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
`;

export const variants = {
  H1: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['48']};
    line-height: 130%;
  `,
  H2: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['44']};
    line-height: 130%;
  `,
  H3: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['40']};
    line-height: 130%;
  `,
  T1: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['32']};
    line-height: 150%;
  `,
  T2: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['28']};
    line-height: 150%;
  `,
  T3: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['24']};
    line-height: 150%;
  `,
  L1: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['20']};
    line-height: 150%;
  `,
  L2: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['16']};
    line-height: 150%;
  `,
  LI1: css`
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize['16']};
    line-height: 150%;
    text-decoration-line: underline;
  `,
  LI2: css`
    font-style: normal;
    font-weight: 500;
    font-size: ${props => props.theme.fontSize['16']};
    line-height: 150%;
    text-decoration-line: underline;
  `,
  C1: css`
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
  `,
  C2: css`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  `,
  C3: css`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  `,
  C4: css`
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 150%;
  `,
  C5: css`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
  `,
};

const StyleTypo = styled.p`
  ${props => (props.variant ? variants[props.variant] : variants[props['C1']])};
  margin: ${props => `${props.theme.spacing(1)} 0`};
  color: ${props => props.theme.palette[props.color]};
  white-space: pre-line;
  ${fontFamily}
`;

const Typography = props => {
  const { variant, language, color, children, ...other } = props;

  return typeof children === 'string' ? (
    <StyleTypo
      variant={variant}
      language={language}
      color={color}
      {...other}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children.replace(/\\n/g, '<br/>')) }}
    ></StyleTypo>
  ) : (
    <StyleTypo variant={variant} language={language} color={color} {...other}>
      {children}
    </StyleTypo>
  );
};

Typography.defaultProps = {
  color: 'white',
};

Typography.propTypes = {
  variant: PropTypes.oneOf(['H1', 'H2', 'H3', 'T1', 'T2', 'T3', 'L1', 'L2', 'LI1', 'LI2', 'C1', 'C2', 'C3', 'C4', 'C5']),
  language: PropTypes.oneOf(['ko', 'en']),
  color: PropTypes.oneOf([
    'primaryBlue',
    'secondaryBlue',
    'alert',
    'disable',
    'black',
    'darkgray',
    'darkgray2',
    'darkgray3',
    'gray',
    'gray2',
    'lightgray',
    'lightgray2',
    'white',
    'channelWhite',
    'yellow',
  ]),
  children: PropTypes.node,
};

export default Typography;
