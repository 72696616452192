import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import _ from 'lodash';

import LanguageService from '../services/LanguageService';

const store = persist(
  (set, get) => ({
    language: 'en',
    languageList: [
      {
        value: 'ko',
        lang_code: 'header_kor_label_1',
        value_ko: '한국어',
        value_en: 'KOR',
      },
      {
        value: 'en',
        lang_code: 'header_eng_label_1',
        value_ko: '영어',
        value_en: 'ENG',
      },
    ],
    languagePack: [],
    setLanguage: language => set({ language }),
    getLanguage: () => get().language,
    setLanguagePack: languagePack => set({ languagePack }),
    getLanguagePack: async () => {
      if (_.isEmpty(get().languagePack)) {
        // get().fetchLanguagePack();
        const _res = await LanguageService.getList();
        if (!_.isEmpty(_res?.items)) set({ languagePack: _res.items });
        return get().languagePack;
      } else return get().languagePack;
    },
    fetchLanguagePack: async () => {
      const _res = await LanguageService.getList();
      if (!_.isEmpty(_res?.items)) set({ languagePack: _res.items });
    },
  }),
  {
    name: 'language',
  }
);

const useLanguage = create(process.env.NODE_ENV !== 'production' ? devtools(subscribeWithSelector(store)) : store);

useLanguage.getState().fetchLanguagePack();
useLanguage.subscribe(state => state.language);
useLanguage.subscribe(state => state.languagePack);

export default useLanguage;
