import axiosInstance from '../components/common/axiosInstance';

/**
 * 메인 팝업 공지 가져오기
 * @returns {Promise}
 */
const getMainPopup = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/popup')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * 헬프센터 메인 - 공지사항 목록
 * @returns {Promise}
 */
const getMainNotice = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/notice/main')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * 공지사항 목록
 * @param {number} page     - 조회할 페이지
 * @param {number} size     - 페이지 당 갯수
 * @returns {Promise}
 */
const getNoticeList = (page = 0, size = 10) =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get(`/notice?page=${page}&size=${size}`)
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * 공지사항 상세
 * @param {string} uid    - 게시물 아이디
 * @returns {Promise}
 */
const getNoticeItem = uid =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get(`/notice/${uid}`)
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * FAQ 카테고리
 * @returns {Promise}
 */
const getFaqCategories = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/faq/cate/F')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * FAQ 목록
 * @returns {Promise}
 */
const getFaqList = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/faq/F')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * 자주묻는질문 목록
 * @returns {Promise}
 */
const getTopFaqList = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/faq/freq/F')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * 가이드 카테고리
 * @returns {Promise}
 */
const getGuideCategories = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/faq/cate/G')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * 가이드 목록
 * @returns {Promise}
 */
const getGuideList = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/faq/G')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

/**
 * 가이드 대표 목록
 * @returns {Promise}
 */
const getTopGuideList = () =>
  new Promise((resolve, reject) => {
    axiosInstance
      .get('/faq/freq/G')
      .then(res => resolve(res.data.items?.data))
      .catch(reject);
  });

const HelpService = {
  getMainPopup,
  getMainNotice,
  getNoticeList,
  getNoticeItem,
  getFaqCategories,
  getFaqList,
  getTopFaqList,
  getGuideCategories,
  getGuideList,
  getTopGuideList,
};

export default HelpService;
