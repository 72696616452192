import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import DOMPurify from 'dompurify';

import usePreventScroll from '../../../hooks/usePreventScroll';

import Portal from '../Portal';
import Close from '../icon/Close';
import Typography from '../typography/Typography';
import Button from '../button/Button';
import IconButton from '../button/IconButton';
import { ModalRoot, ModalOverlay, ModalContainer, ModalWrapper, ModalActionContainer } from '../../../styles/ModalStyles';

const Modal = ({ open, onClose, size, children, ...other }) => {
  usePreventScroll(open);

  if (open) {
    return (
      <Portal type='modal'>
        <ModalRoot>
          <ModalOverlay />
          <ModalContainer>
            <ModalWrapper size={size} {...other}>
              <div style={{ textAlign: 'right' }}>
                <IconButton onClick={() => onClose()}>
                  <Close />
                </IconButton>
              </div>
              <div style={{ overflowY: 'auto', maxHeight: '600px' }}>
                <div>{children}</div>
              </div>
            </ModalWrapper>
          </ModalContainer>
        </ModalRoot>
      </Portal>
    );
  }
};

Modal.defaultProps = {
  open: false,
  size: 'md',
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
};

const ModalTitle = ({ children }) => {
  const theme = useContext(ThemeContext);
  return (
    <div className='select-none' style={{ marginBottom: theme.spacing(2), textAlign: 'start' }}>
      <Typography variant={'T2'}>{children}</Typography>
    </div>
  );
};

const ModalTitleCus = ({ children }) => {
  const theme = useContext(ThemeContext);
  return (
    <div className='select-none' style={{ marginBottom: theme.spacing(4), textAlign: 'center' }}>
      <Typography variant={'T2'}>{children}</Typography>
    </div>
  );
};

const ModalContent = ({ children }) => {
  return typeof children === 'string' ? (
    <div
      className='text-start whitespace-pre-wrap select-none'
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children.replace(/\\n/g, '<br/>')) }}
    ></div>
  ) : (
    <div className='text-start whitespace-pre-wrap select-none'>{children}</div>
  );
};

const ModalActions = ({ confirm, cancel }) => {
  return (
    <ModalActionContainer>
      {cancel && (
        <Button color={'secondary'} fullWidth onClick={cancel.action}>
          {cancel.label}
        </Button>
      )}
      {confirm && (
        <Button color={'primary'} fullWidth onClick={confirm.action} disabled={confirm?.disabled || false}>
          {confirm.label}
        </Button>
      )}
    </ModalActionContainer>
  );
};

ModalActions.propTypes = {
  confirm: PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  cancel: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
};

export { Modal, ModalTitle, ModalContent, ModalActions, ModalTitleCus };
