export const checkRegExp = (type, str) => {
  const _regexp = { ...COMMON, ...ACCOUNT, ...CONTACT, ...CLIP, ...PAYMENT, ...PAYEE_INFO_MODAL, ...HDD };
  let regex = _regexp[type.toUpperCase()];
  return regex.test(str);
};

export const COMMON = {
  FILE_EXTENSION: /(\.\w+$)/gim,
  VOD_FILE_TYPE: /(.mov|.mp4)$/i,
  LICENSE_DOC_FILE_TYPE: /(.pdf)$/i,
  BRACKET: /\([^)]*\)/g,
  SQUARE_BRACKET: /\[(.+)\]/g,
  ENG: /[A-Za-z]/g,
  NO_KOR: /[`\\ㄱ-ㅎ가-힣]/g,
  KOR: /[ㄱ-ㅎㅏ-ㅣ가-힣]{1,}/,
  END_COMMA: /,\s*$/,
};

/* 계정 정보 */
export const ACCOUNT = {
  NAME: /^[A-Za-z]{1,32}$/g,
  FIRST_NAME: /^[A-Za-z]{1,32}$/g,
  LAST_NAME: /^[A-Za-z]{1,32}$/g,
  PROFILE_NAME: /^[A-Za-z\s\d-_.]{1,32}$/,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD: /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[@~!#$%^&*|;:/?])\S[A-Za-z0-9@~!#$%^&*|;:/?]*$/, //허용 특수문자 이외는 입력안되게
  FTP_PASS: /^[A-Za-z\d-_~!@#$%^&*|;:/?]{4,20}$/,
  SOFTWARE: /^[a-zA-Z0-9\s-_~!@#$%^&*(),.?\"\'//;:{}|<>\[\]₩+=]{0,64}$/,
  EQUIPMENT: /^[a-zA-Z0-9\s-_~!@#$%^&*(),.?\"\'//;:{}|<>\[\]₩+=]{0,64}$/,
  SERVICE: /^[a-zA-Z0-9\s-_~!@#$%^&*(),.?\"\'//;:{}|<>\[\]₩+=]{0,64}$/,
  CREATOR_INFO: /^[a-zA-Z0-9\s-_~!@#$%^&*(),.?\"\'//;:{}|<>\[\]₩+=]{0,1000}$/,
};

export const ACCOUNT_MAX_LENGTH = {
  PROFILE_NAME: 32,
  FIRST_NAME: 32,
  LAST_NAME: 32,
  PASSWORD: 20,
  FTP_PASS: 20,
  SOFTWARE: 64,
  EQUIPMENT: 64,
  SERVICE: 64,
  CREATOR_INFO: 1000,
};

/* 연락 정보 */
export const CONTACT = {
  DETAIL_ADDRESS: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,62}$/,
  PROVINCE: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{0,24}$/,
  CITY: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,24}$/,
  ZIPCODE: /^[A-Za-z\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,9}$/,
  PHONE: /^[0-9]{6,15}$/,
};

export const CONTACT_MAX_LENGTH = {
  DETAIL_ADDRESS: 62,
  PROVINCE: 24,
  CITY: 24,
  ZIPCODE: 9,
  PHONE: 15,
};

/* 포트폴리오 - 클립 정보 */
export const CLIP = {
  TITLE: /^[a-zA-Z\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{0,200}$/,
  DESCRIPTION: /^[a-zA-Z\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{0,200}$/,
  LOCATION: /^[a-zA-Z\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{0,100}$/,
  KEYWORDS: /^[a-zA-Z\d\s-\{\}\[\]/?;:|#@*~()!^+<>._$%&,='"₩]{0,}$/,
};

export const CLIP_MAX_LENGTH = {
  TITLE: 200,
  DESCRIPTION: 200,
  LOCATION: 100,
  KEYWORDS: 50,
};

/* 지급 정보 */
export const PAYMENT = {
  ...(({ PROVINCE, ...o }) => o)(CONTACT),
  PAYOUT_EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PAYABLE_TO: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\s]{1,}$/,
  IDENTITY_NUM: /^[A-Za-z\d]{8,}$/,
  STATE: CONTACT['PROVINCE'],
  COMPANY_NAME: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,32}$/,
  BANK_ACCOUNT_NUM: /^[0-9]\S{1,62}$/,
  BANK_NAME: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,62}$/,
  BANK_DETAIL_ADDRESS: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,62}$/,
  BANK_CITY: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,24}$/,
  BANK_STATE: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,62}$/,
  BANK_ZIPCODE: CONTACT['ZIPCODE'],
  SWIFT_CODE: /^[A-Za-z\d]\S{1,62}$/,
  IBAN_NUM: /^[A-Za-z\d]\S{1,62}$/,
  ACCOUNT_HOLDER: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,62}$/, //KR only
  SORT_CODE: /^[A-Za-z\d-]\S{1,62}$/, //GB only
  ABA_NO: /^[A-Za-z\d]\S{1,62}$/, //US only
  CHIP_CODE: /^[A-Za-z\d]\S{1,62}$/, //US only
  TRANSIT_NO: /^[A-Za-z\d]\S{1,62}$/, //CA only
  MINIMUM: /^[0-9]\S{3,4}$/,
};

export const PAYMENT_MAX_LENGTH = {
  ...(({ PROVINCE, ...o }) => o)(CONTACT_MAX_LENGTH),
  STATE: CONTACT_MAX_LENGTH['PROVINCE'],
  COMPANY_NAME: 32,
  BANK_ACCOUNT_NUM: 62,
  BANK_NAME: 62,
  BANK_DETAIL_ADDRESS: 62,
  BANK_CITY: 24,
  BANK_STATE: 62,
  BANK_ZIPCODE: CONTACT_MAX_LENGTH['ZIPCODE'],
  IBAN_NUM: 62,
  SWIFT_CODE: 62,
  ACCOUNT_HOLDER: 62, //KR only
  SORT_CODE: 62, //GB only
  ABA_NO: 62, //US only
  CHIP_CODE: 62, //US only
  TRANSIT_NO: 62, //CA only
  MINIMUM: 4,
};

/* 수취인 정보 수정 */
export const PAYEE_INFO_MODAL = {
  PAYEE: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\s]{1,32}$/,
  COMPANY_NAME: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{1,32}$/,
  REASON: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\d\s-\{\}\[\]\(\)/?;:|#@*~()!^+<>._$%&,='"₩]{10,500}$/,
};

export const PAYEE_INFO_MODAL_MAX_LENGTH = {
  PAYEE: 32,
  COMPANY_NAME: 32,
  REASON: 500,
};

/* 업로드 - HDD */
export const HDD = {
  RECIPIENT: /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣\s]{1,32}$/,
  VOLUME: /^[0-9]{0,3}$/,
  ...CONTACT,
};

export const HDD_MAX_LENGTH = {
  RECIPIENT: 32,
  VOLUME: 3,
  REQUESTS: 1000,
  ...CONTACT_MAX_LENGTH,
};
