import React, { createContext, useState } from 'react';

import { ModifiedData } from '../components/modal/upload/MetadataModify';

const ModifyMetadataContext = createContext({
  init: () => {},
  handleModalOpen: () => {},
  handleModalClose: () => {},
  handleModalSubmit: () => {},
  isModified: false,
  handleModified: () => {},
  draft: null,
  handleDraft: () => {},
  action: '',
  handleAction: () => {},
  isSubmitted: false,
  handleSubmitted: () => {},
});

const ModifyMetadataProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [draft, setDraft] = useState(null);
  const [action, setAction] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const init = () => {
    setModalOpen(false);
    setIsModified(false);
    setIsSubmitted(false);
    setAction();
  };
  const handleModalOpen = action => {
    setModalOpen(true);
    setAction(() => action);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setAction();
  };
  const handleModalSubmit = () => {
    action();
    setIsModified(false);
    handleModalClose();
  };
  const handleModified = bool => setIsModified(bool);
  const handleDraft = item => setDraft(item);
  const handleAction = func => setAction(() => func);
  const handleSubmitted = bool => setIsSubmitted(bool);

  return (
    <ModifyMetadataContext.Provider
      value={{
        init,
        modalOpen,
        handleModalOpen,
        isModified,
        handleModified,
        draft,
        handleDraft,
        action,
        handleAction,
        isSubmitted,
        handleSubmitted,
      }}
    >
      {children}
      <ModifiedData open={modalOpen} onClose={handleModalClose} onSubmit={handleModalSubmit} />
    </ModifyMetadataContext.Provider>
  );
};

export { ModifyMetadataContext, ModifyMetadataProvider };
