import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { shallow } from 'zustand/shallow';

import useLanguage from '../store/language';

export default function useLanguagePack(code) {
  const { language, languagePack, fetchLanguagePack } = useLanguage(
    state => ({
      language: state.language,
      languagePack: state.languagePack,
      fetchLanguagePack: state.fetchLanguagePack,
    }),
    shallow
  );

  const text = useMemo(() => {
    if (!code) return '';
    else return languagePack.find(l => l.lang_code === code)?.[language] || languagePack?.find(l => l.lang_code === code)?.['ko'];
  }, [language, languagePack, code]);

  useEffect(() => {
    if (_.isEmpty(text) || _.isUndefined(text)) {
      fetchLanguagePack();
    }
  }, [text]);

  return text;
}
