import React from 'react';
import styled from 'styled-components';

export default function IconButton({ onClick, children, ...others }) {
  return (
    <Button onClick={onClick} type='button' className={'flex items-center justify-center'} {...others}>
      {children}
    </Button>
  );
}

const Button = styled.button`
  appearance: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  vertical-align: middle;
`;
