import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ModifyMetadataProvider } from './context/ModifyMetadataContext';

const Home = lazy(() => import('./pages/Home'));

/* Help Center */
const HelpCenter = lazy(() => import('./pages/help/HelpCenter'));
const Faq = lazy(() => import('./pages/help/Faq'));
const Guide = lazy(() => import('./pages/help/Guide'));
const Notice = lazy(() => import('./pages/help/Notice'));
const NoticeDetail = lazy(() => import('./pages/help/NoticeDetail'));

/* Only for Admin */
const AdminToUserDashboard = lazy(() => import('./pages/admin/Dashboard'));
const MigrationContract = lazy(() => import('./pages/admin/contract/MigrationContract'));
const ContractViewer = lazy(() => import('./pages/admin/contract/ContractViewer'));

/* Account */
const SignIn = lazy(() => import('./pages/account/SignIn'));
const SignUp = lazy(() => import('./pages/account/SignUp'));
const Verify = lazy(() => import('./pages/account/Verify'));
const Complete = lazy(() => import('./pages/account/Complete'));
const Migration = lazy(() => import('./pages/account/Migration'));
const FindPw = lazy(() => import('./pages/account/FindPw'));
const InputPw = lazy(() => import('./pages/account/InputPw'));
const Agreement = lazy(() => import('./pages/account/Agreement'));
const MigrationVerify = lazy(() => import('./pages/account/MigrationVerify'));

/* User */
const MyPage = lazy(() => import('./pages/user/MyPage'));
const PaymentInfo = lazy(() => import('./pages/user/PaymentInfo'));
const ProfileEdit = lazy(() => import('./pages/user/ProfileEdit'));
const ChangePw = lazy(() => import('./pages/account/ChangePw'));

/* Dashboard */
const Dashboard = lazy(() => import('./pages/Dashboard'));

/* Portfolio */
const Portfolio = lazy(() => import('./pages/portfolio/Portfolio'));

/* Upload */
const Upload = lazy(() => import('./pages/upload/Upload'));
const Submit = lazy(() => import('./pages/upload/Submit'));
const Review = lazy(() => import('./pages/upload/Review'));
const Pending = lazy(() => import('./pages/upload/Pending'));
const Release = lazy(() => import('./pages/upload/Release'));

/* Earning */
const Earning = lazy(() => import('./pages/earning/Earning'));
const PayoutHistory = lazy(() => import('./pages/earning/PayoutHistory'));

/* Terms */
const Service = lazy(() => import('./pages/terms/service/Service'));
const PersonalInfo = lazy(() => import('./pages/terms/personal/PersonalInfo'));
const Privacy = lazy(() => import('./pages/terms/privacy/Privacy'));
const License = lazy(() => import('./pages/terms/license/License'));
/* Terms - old */
const Service221101 = lazy(() => import('./pages/terms/service/old/Service221101'));
const Service230206 = lazy(() => import('./pages/terms/service/old/Service230206'));
const Service230502 = lazy(() => import('./pages/terms/service/old/Service230502'));
const Privacy221101 = lazy(() => import('./pages/terms/privacy/old/Privacy221101'));
const Privacy230518 = lazy(() => import('./pages/terms/privacy/old/Privacy230518'));
const License230502 = lazy(() => import('./pages/terms/license/old/License230502'));
const License231011 = lazy(() => import('./pages/terms/license/old/License231011'));

const NotFound = lazy(() => import('./pages/NotFound'));

function App() {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
          }}
        >
          <img src={'/assets/image/loading.gif'} style={{ width: '150px' }} />
        </div>
      }
    >
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/dashboard' element={<Dashboard />} exact />

        {/* Help Center */}
        <Route path='/help' element={<HelpCenter />} exact />
        <Route path='/faq' element={<Faq />} exact />
        <Route path='/guide' element={<Guide />} exact />

        <Route path='/notice' element={<Notice />} exact />
        <Route path='/notice/:id' element={<NoticeDetail />} exact />

        {/* Only for Admin */}
        <Route path='/user/temp/dashboard/:token' element={<AdminToUserDashboard />} exact />
        <Route path='/admin/contract' element={<MigrationContract />} exact />
        <Route path='/admin/contract/viewer' element={<ContractViewer />} exact />

        {/* Account */}
        <Route path='/signin' element={<SignIn />} exact />
        <Route path='/signup' element={<SignUp />} exact />
        <Route path='/verify' element={<Verify />} exact />
        <Route path='/complete/:uuid' element={<Complete />} exact />
        <Route path='/migration' element={<Migration />} exact />
        <Route path='/migration/verify/:uuid' element={<MigrationVerify />} exact />
        <Route path='/findpw' element={<FindPw />} exact />
        <Route path='/inputpw/:uuid' element={<InputPw />} exact />
        <Route path='/agree' element={<Agreement />} exact />

        {/* User */}
        <Route path='/mypage' element={<MyPage />} exact />
        <Route path='/mypage/profile' element={<ProfileEdit />} exact />
        <Route path='/payment' element={<PaymentInfo />} exact />
        <Route path='/changepw' element={<ChangePw />} exact />

        {/* Portfolio */}
        <Route path='/portfolio' element={<Portfolio />} exact />

        {/* Upload */}
        <Route path='/upload' element={<Upload />} exact />
        <Route
          path='/submit'
          element={
            <ModifyMetadataProvider>
              <Submit />
            </ModifyMetadataProvider>
          }
          exact
        />
        <Route path='/review' element={<Review />} exact />
        <Route
          path='/pending'
          element={
            <ModifyMetadataProvider>
              <Pending />
            </ModifyMetadataProvider>
          }
          exact
        />
        <Route path='/release' element={<Release />} exact />

        {/* Earning */}
        <Route path='/earning' element={<Earning />} exact />
        <Route path='/payout' element={<PayoutHistory />} exact />

        {/* Terms */}
        <Route path='/terms/service' element={<Service />} exact />
        <Route path='/terms/personal' element={<PersonalInfo />} exact />
        <Route path='/terms/privacy' element={<Privacy />} exact />
        <Route path='/terms/license' element={<License />} exact />
        {/* Terms - old */}
        <Route path='/terms/service/221101' element={<Service221101 />} exact />
        <Route path='/terms/service/230206' element={<Service230206 />} exact />
        <Route path='/terms/service/230502' element={<Service230502 />} exact />
        <Route path='/terms/privacy/221101' element={<Privacy221101 />} exact />
        <Route path='/terms/privacy/230518' element={<Privacy230518 />} exact />
        <Route path='/terms/license/230502' element={<License230502 />} exact />
        <Route path='/terms/license/231011' element={<License231011 />} exact />

        <Route path='/error/404' element={<NotFound />} exact />
        <Route path='/error/500' element={<NotFound />} exact />

        <Route path='/*' element={<NotFound />} exact />
      </Routes>
    </Suspense>
  );
}

export default App;
