import styled from 'styled-components';

export const ModalRoot = styled.div.attrs(props => ({
  role: 'presentation',
}))`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
`;

export const ModalOverlay = styled.div`
  opacity: 0.5;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.dim['50']};
  /* flex-flow: row wrap; */
  /* box-sizing: border-box; */
`;

export const ModalContainer = styled.div.attrs(props => ({
  role: 'presentation',
  tabIndex: '-1',
}))`
  height: 100%;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1000;
    box-sizing: border-box; */
`;

export const ModalWrapper = styled.div.attrs(props => ({
  role: 'dialog',
  tabIndex: '0',
}))`
  box-sizing: border-box;
  margin: auto;
  background-color: ${({ theme }) => theme.palette.modal.background};
  /* backdrop-filter: blur(14px); */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  color: ${props => props.theme.palette.white};
  padding: ${({ theme }) => theme.spacing(8, 0)};
  width: ${props => (props.size === 'lg' ? '1200px' : props.size === 'sm' ? '300px' : '684px')};
  & > div:first-of-type {
    padding: ${({ theme }) => theme.spacing(0, 10)};
    margin-bottom: ${props => props.theme.spacing(1)};
  }
  & > div:last-of-type {
    padding: ${({ theme }) => theme.spacing(0, 10)};
    &::-webkit-scrollbar {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 10px;
      background-clip: padding-box;
      border: 4px solid transparent;
      &:hover {
        background-color: #9d9d9d;
      }
    }
  }
`;

export const ModalActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.spacing(3)};
  margin-top: ${props => props.theme.spacing(4)};
  & > button {
    line-height: 100% !important;
  }
`;
