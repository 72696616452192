import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import _ from 'lodash';

import ProfileService from '../services/ProfileService';

const store = persist(
  set => ({
    user: {},
    setUser: user => set({ user }),
    fetchUser: async () => {
      const _res = await ProfileService.getProfile();
      if (!_.isEmpty(_res?.data?.items?.member)) {
        set({ user: _res.data?.items?.member });
      }
    },
    fetchUserCb: async cb => {
      const _res = await ProfileService.getProfile();
      if (!_.isEmpty(_res?.data?.items?.member)) {
        set({ user: _res.data?.items?.member });
        cb();
      }
    },
    initUser: () => set({ user: {} }),
  }),
  {
    name: 'user',
  }
);

const useUser = create(process.env.NODE_ENV !== 'production' ? devtools(subscribeWithSelector(store)) : store);

useUser.subscribe(
  state => state.user,
  val => console.log('[STORE] user changed:: ', val)
);

export default useUser;
