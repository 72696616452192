import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default function ToastInfo() {
  const theme = useContext(ThemeContext);
  return (
    <svg width='33' height='34' viewBox='0 0 33 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31 17C31 25.0081 24.5081 31.5 16.5 31.5C8.49187 31.5 2 25.0081 2 17C2 8.99187 8.49187 2.5 16.5 2.5C24.5081 2.5 31 8.99187 31 17ZM33 17C33 26.1127 25.6127 33.5 16.5 33.5C7.3873 33.5 0 26.1127 0 17C0 7.8873 7.3873 0.5 16.5 0.5C25.6127 0.5 33 7.8873 33 17ZM17.666 11.832H15.3327V9.4987H17.666V11.832ZM17.666 23.5H15.3327V13.3889H17.666L17.666 23.5Z'
        fill={theme.palette.toast.info.icon}
      />
    </svg>
  );
}
