import React, { createContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import Portal from '../components/common/Portal';
import Toast, { ToastBox } from '../components/common/display/Toast';

export const ToastContext = createContext([]);

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        setToasts(toasts => toasts.slice(1));
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    ({ type, title, content }) => {
      setToasts(prev => [
        ...prev,
        {
          id: `toast-${prev.length + 1}`,
          type,
          title,
          content,
        },
      ]);
    },
    [setToasts]
  );

  // const toastActions = useMemo(
  //   () => ({
  //     create(title, content) {
  //       setToasts(prev => [...prev, { id: 'toast-' + (toasts?.length || 0) + 1, title, content }]);
  //     },
  //     remove(id) {
  //       setToasts(prev => prev.filter(x => x.id !== id));
  //     },
  //     init() {
  //       setToasts(prev => []);
  //     },
  //   }),
  //   [],
  // );

  // const value = useMemo(() => ({ items: toasts, actions: toastActions }), [toasts, toastActions]);

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {!_.isEmpty(toasts) && (
        <Portal type='toast'>
          <ToastBox>
            {toasts.map(toast => (
              <Toast key={toast.id} type={toast.type} title={toast.title} content={toast.content} />
            ))}
          </ToastBox>
        </Portal>
      )}
    </ToastContext.Provider>
  );
  // return <ToastContext.Provider value={{ items: toastItems, actions: toastActions }}>{children}</ToastContext.Provider>;
};

export default ToastProvider;
