import axios from 'axios';
import { getCookie, removeCookie } from '../../utils/Cookies';
import { TOKEN_NAME } from '../../constants';

import useLanguage from '../../store/language';
import useUser from '../../store/user';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  // withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    try {
      if (getCookie(TOKEN_NAME)) config.headers.Authorization = `Bearer ${getCookie(TOKEN_NAME)}`;
      config.headers['Accept-Language'] = useLanguage.getState().getLanguage() === 'ko' ? 'ko_KR' : 'en_US';
      return config;
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Error occurred in axios.interceptors.request:: ', e);
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const { response } = error;
    if (response.status === 401) {
      removeCookie(TOKEN_NAME, {
        path: '/',
      });
      useUser.getState().initUser();
      window.location.replace(`${process.env.REACT_APP_URL}/signin`);
      // // --- token refresh 요청 ---
      // const originalRequest = config;
      // const refreshToken = cookies;
      // const { data } = await axios.post(`${API_URL}/oauth2/refresh/artists`, {
      //   refreshToken,
      // });
      // const { access_token, refresh_key } = data;
      // setCookie('_user_', refresh_key);
      // if (getCookie('_user_')) axios.defaults.headers.common.Authorization = `Bearer ${refresh_key}`;
      // originalRequest.headers.Authorization = `Bearer ${refresh_key}`;
      // return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
