import { createGlobalStyle } from 'styled-components';

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Noto Sans KR', sans-serif;
        src: url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap')
    }
    @font-face {
        font-family: 'Montserrat', sans-serif;
        src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
    }
`;

export default GlobalFonts;
