import React, { createContext, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import Portal from '../components/common/Portal';
import MainPopup, { PopupContainer } from '../components/modal/MainPopup';
import HelpService from '../services/HelpService';
import { getCookie, setCookie } from '../utils/Cookies';

import useUser from '../store/user';
import useLanguage from '../store/language';

export const PopupContext = createContext({
  popup: [],
});

const PopupProvider = ({ children }) => {
  const location = useLocation();
  const { language } = useLanguage(
    state => ({
      language: state.language,
    }),
    shallow
  );
  const { user } = useUser(
    state => ({
      user: state.user,
    }),
    shallow
  );
  const COOKIE_NAME = useMemo(() => `${user?.folder}_hidden_popup`, [user]);
  const [popup, setPopup] = useState([]); // 전체 팝업
  const enabledPath = useMemo(() => {
    if (location.pathname === '/dashboard') return true;
    else return false;
  }, [location]);
  const enabled = useMemo(() => {
    let rtn = false;
    if (enabledPath && !_.isEmpty(popup)) rtn = true;
    return rtn;
  }, [enabledPath, popup]);

  useLayoutEffect(() => {
    if (enabledPath) init();
  }, [enabledPath, language]);

  const isOpened = useCallback(
    id => {
      return popup.flatMap(x => x.uid).includes(id);
    },
    [popup]
  );

  const checkHidden = array => {
    const hiddenArr = getCookie(COOKIE_NAME) && !_.isEmpty(getCookie(COOKIE_NAME)) ? getCookie(COOKIE_NAME) : [];
    if (!_.isEmpty(array) && !_.isEmpty(hiddenArr)) {
      const _arranged = array.reduce((acc, cur) => {
        if (!hiddenArr.includes(cur.uid)) acc.push(cur);
        return acc;
      }, []);
      setPopup(_arranged);
    } else {
      setPopup(array);
    }
  };

  const init = () => {
    HelpService.getMainPopup()
      .then(res => {
        const _response = _.cloneDeep(res).filter(x => x.notice_type !== (language === 'ko' ? 2 : 1));
        checkHidden(_response);
      })
      .catch(err => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Error occurred while get main popup list:: ', JSON.stringify(err, null, 2));
        }
      });
  };

  const closePopup = id => {
    if (!_.isEmpty(popup)) {
      setPopup(prev => prev.filter(x => x.uid !== id));
    }
  };

  const handleHideToday = id => {
    const hiddenArr = getCookie(COOKIE_NAME) && !_.isEmpty(getCookie(COOKIE_NAME)) ? getCookie(COOKIE_NAME) : [];
    if (!hiddenArr.includes(id)) {
      hiddenArr.push(id);
      const _expireAt = new Date();
      _expireAt.setDate(_expireAt.getDate() + 1);
      setCookie(COOKIE_NAME, JSON.stringify(hiddenArr), {
        path: '/',
        expires: _expireAt,
      });
      setPopup(prev => prev.filter(x => x.uid !== id));
    }
  };

  return (
    <PopupContext.Provider value={{ popup }}>
      {children}
      {enabled && (
        <Portal type='popup'>
          <PopupContainer>
            {popup.map(x => (
              <MainPopup key={x.uid} data={x} onClose={() => closePopup(x.uid)} handleHidden={() => handleHideToday(x.uid)} />
            ))}
          </PopupContainer>
        </Portal>
      )}
    </PopupContext.Provider>
  );
};

export default PopupProvider;
