import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalTitle, ModalContent, ModalActions } from '../../common/display/Modal';
import useLanguagePack from '../../../hooks/useLanguagePack';
import { COMMON } from '../../../constants/regexp';
/**
 * 제출 확인 Modal
 */
const ConfirmSubmit = ({ open, onClose, onSubmit }) => {
  const languageKeyStrings = {
    title: useLanguagePack('submit621savesubmitmodal_title_1'),
    content: useLanguagePack('submit621savesubmitmodal_text_1'),
    confirmButton: useLanguagePack('submit621savesubmitmodal_buttonlabel_2'),
    cancelButton: useLanguagePack('submit621savesubmitmodal_buttonlabel_1'),
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{languageKeyStrings.title}</ModalTitle>
      <ModalContent>{languageKeyStrings.content}</ModalContent>
      <ModalActions
        confirm={{
          label: languageKeyStrings.confirmButton,
          action: onSubmit,
        }}
        cancel={{
          label: languageKeyStrings.cancelButton,
          action: onClose,
        }}
      />
    </Modal>
  );
};

/**
 * 심사 요청 확인 Modal
 */
const JudgeSubmit = ({ open, onClose, onSubmit, judge, type }) => {
  const languageKeyStrings = {
    title: useLanguagePack('judge_sentence_18'),
    content: useLanguagePack('judge_sentence_19'),
    re_title: useLanguagePack('judge_sentence_27'),
    re_content: useLanguagePack('judge_sentence_28'),
    confirmButton: useLanguagePack('submit621savesubmitmodal_buttonlabel_2'),
    cancelButton: useLanguagePack('submit621savesubmitmodal_buttonlabel_1'),
    video: useLanguagePack('uploadpp61_text_9'),
    image: useLanguagePack('uploadpp61_text_10'),
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{type == 'pending' ? languageKeyStrings.re_title : languageKeyStrings.title}</ModalTitle>
      <ModalContent>
        {type == 'pending'
          ? languageKeyStrings.re_content
          : languageKeyStrings.content.replace(
              COMMON.BRACKET,
              `${judge.selected == 'video' ? languageKeyStrings.video : languageKeyStrings.image}`
            )}
      </ModalContent>
      <ModalActions
        confirm={{
          label: languageKeyStrings.confirmButton,
          action: onSubmit,
        }}
        cancel={{
          label: languageKeyStrings.cancelButton,
          action: onClose,
        }}
      />
    </Modal>
  );
};

/**
 * 심사 작가 컨텐츠 개수 미달 알림 팝업
 */
const JudgeCountSubmit = ({ open, onClose, judge }) => {
  let videoTitle = useLanguagePack('judge_sentence_12');
  let videoContent = useLanguagePack('judge_sentence_15');

  let imageTitle = useLanguagePack('judge_sentence_13');
  let imageContent = useLanguagePack('judge_sentence_16');

  const languageKeyStrings = {
    title: `${judge.selected == 'video' ? videoTitle : imageTitle}`,
    content: useLanguagePack('judge_sentence_14'),
    content2: `- ${judge.selected == 'video' ? videoContent : imageContent}`,
    // confirmButton: useLanguagePack('submit621savesubmitmodal_buttonlabel_2'),
    cancelButton: useLanguagePack('judge_sentence_17'),
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{languageKeyStrings.title}</ModalTitle>
      <ModalContent>{languageKeyStrings.content}</ModalContent>
      <ModalContent>{languageKeyStrings.content2}</ModalContent>
      <ModalActions
        confirm={{
          label: languageKeyStrings.cancelButton,
          action: onClose,
        }}
      />
    </Modal>
  );
};

/**
 * 저장되지 않았을때 뜨는 Modal
 */
const NotSavedData = ({ open, onClose }) => {
  const languageKeyStrings = {
    title: useLanguagePack('submit621notsavemodal_title_1'),
    content: useLanguagePack('submit621notsavemodal_text_1'),
    button: useLanguagePack('submit621notsavemodal_buttonlabel_1'),
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{languageKeyStrings.title}</ModalTitle>
      <ModalContent>{languageKeyStrings.content}</ModalContent>
      <ModalActions
        confirm={{
          label: languageKeyStrings.button,
          action: onClose,
        }}
      />
    </Modal>
  );
};

/**
 * 비디오 타입 이외의 파일 선택했을때 Modal
 */
const NoneVideoFile = ({ open, onClose, onSubmit }) => {
  const languageKeyStrings = {
    title: useLanguagePack('submit621etcfilemodal_title_1'),
    content: useLanguagePack('submit621etcfilemodal_text_1'),
    confirm: useLanguagePack('submit621etcfilemodal_buttonlabel_2'),
    cancel: useLanguagePack('submit621etcfilemodal_buttonlabel_1'),
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{languageKeyStrings.title}</ModalTitle>
      <ModalContent>{languageKeyStrings.content}</ModalContent>
      <ModalActions
        confirm={{
          label: languageKeyStrings.confirm,
          action: onSubmit,
        }}
        cancel={{
          label: languageKeyStrings.cancel,
          action: onClose,
        }}
      />
    </Modal>
  );
};

/**
 * 편집중인 항목이 있을때 저장하지 않은 상태에서 다른 페이지로 이동할때 Modal
 */
const ModifiedData = ({ open, onClose, onSubmit }) => {
  const languageKeyStrings = {
    title: useLanguagePack('submit621outmodal_title_1'),
    content: useLanguagePack('submit621outmodal_text_1'),
    confirm: useLanguagePack('submit621outmodal_buttonlabel_2'),
    cancel: useLanguagePack('submit621outmodal_buttonlabel_1'),
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{languageKeyStrings.title}</ModalTitle>
      <ModalContent>{languageKeyStrings.content}</ModalContent>
      <ModalActions
        confirm={{
          label: languageKeyStrings.confirm,
          action: onSubmit,
        }}
        cancel={{
          label: languageKeyStrings.cancel,
          action: onClose,
        }}
      />
    </Modal>
  );
};

/**
 * 편집중인 항목이 있을때 저장하지 않은 상태에서 다른 영상 선택할때 Modal
 */
const SelectOtherClipModifying = ({ open, onClose, onSubmit }) => {
  const languageKeyStrings = {
    title: useLanguagePack('submit621outmodal_title_1'),
    content: useLanguagePack('submit621outmodal_text_1'),
    confirm: useLanguagePack('submit621outmodal_buttonlabel_3'),
    cancel: useLanguagePack('submit621outmodal_buttonlabel_1'),
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{languageKeyStrings.title}</ModalTitle>
      <ModalContent>{languageKeyStrings.content}</ModalContent>
      <ModalActions
        confirm={{
          label: languageKeyStrings.confirm,
          action: onSubmit,
        }}
        cancel={{
          label: languageKeyStrings.cancel,
          action: onClose,
        }}
      />
    </Modal>
  );
};

ConfirmSubmit.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
NotSavedData.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
NoneVideoFile.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
ModifiedData.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
SelectOtherClipModifying.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ConfirmSubmit, NotSavedData, NoneVideoFile, ModifiedData, SelectOtherClipModifying, JudgeSubmit, JudgeCountSubmit };
