import _ from 'lodash';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import moment from 'moment';
import { getCookie, setCookie, removeCookie } from '../utils/Cookies';
import useLanguage from '../store/language';
import useCountry from '../store/country';
import useUser from '../store/user';
import { TOKEN_NAME } from '../constants';

/**
 * 콘솔로그에 에러찍기
 * @param {*} error
 * @param {string} desc
 * @returns
 */
export const logErrorConsole = (error, desc) => {
  if (process.env.NODE_ENV !== 'production') {
    return console.log(`Error occurred while ${desc}:: `, JSON.stringify(error, null, 2));
  }
};

/**
 * 토큰 가져오기
 * @returns {string} token
 */
export const getToken = () => {
  // return _token ? `Bearer ${_token}` : null;
  return getCookie(TOKEN_NAME);
};

/**
 * 토큰 설정하기
 * @param {string} _token   - 사용자 토큰
 */
export const setToken = _token => {
  const _today = new Date();
  _today.setDate(_today.getDate() + 5);
  setCookie(TOKEN_NAME, _token, {
    path: '/',
    expires: _today,
  });
};

/**
 * 토큰 제거
 */
export const removeToken = () => {
  return removeCookie(TOKEN_NAME, {
    path: '/',
  });
};

/**
 * 로그아웃 처리
 */
export const signOut = () => {
  if (/\/(help|notice|faq)/.test(window.location.pathname))
    window.location.replace(`${process.env.REACT_APP_URL}${window.location.pathname}`);
  else window.location.replace(`${process.env.REACT_APP_URL}`);
  removeCookie(TOKEN_NAME, {
    path: '/',
  });
  useUser.getState().initUser();
};

/**
 * 언어팩 키스트링 치환
 * @param {string} code   - 키스트링
 * @returns {string}
 */
export const getTextFromLanguagePack = (code, opt) => {
  const _languagePack = localStorage.getItem('language')
    ? JSON.parse(localStorage.getItem('language'))['state'].languagePack
    : useLanguage.getState().getLanguagePack();
  const _language = localStorage.getItem('language')
    ? JSON.parse(localStorage.getItem('language'))['state'].language
    : useLanguage.getState().getLanguage();
  return (
    _languagePack?.find(l => l.lang_code === code || l.lang_code === code + `_${opt}`)?.[_language] ||
    _languagePack?.find(l => l.lang_code === code || l.lang_code === code + `_${opt}`)?.['ko']
  );
};

/**
 * 첫문자 대문자로 변환
 * @param {string} str    - 문자열
 * @returns {string} 변환된 문자열
 */
export const capitalizeText = str => str && str[0].toUpperCase() + str.slice(1).toLowerCase();

/**
 * 숫자 천단위 콤마 찍기
 * @param {any} num       - 숫자
 * @returns {string}
 */
export const addComma = num => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  let _num = num + '';
  return _num.toString().replace(regexp, ',');
};

/**
 * 숫자만 입력받게 하기
 * @param {any} value
 */
export const inputNumericOnly = value => value.replace(/[^0-9]/g, '');

/**
 * 숫자 문자열의 시작을 0으로 채워 길이 맞추기
 * @param {any} num             - 숫자 문자열
 * @param {number} length       - 목표 문자열 길이
 * @returns {string}
 */
export const numberPad = (num, length) => num.toString().padStart(length, '0');

/**
 * 모바일 브라우저 확인
 * @returns {boolean}
 */
export const checkIsMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Mobile|IEMobile|Windows Phone|Opera Mini/i.test(navigator.userAgent);
};

/**
 * 국기 아이콘 + 국가명
 * @param {string} code       - 국가코드
 * @returns {string}
 */
export const getCountry = code => {
  const _language = useLanguage.getState().getLanguage();
  const _countryList = useCountry.getState().getCountryList();
  const _country = _countryList.find(c => c['country_code2'] === code);
  return (
    <span>
      {getUnicodeFlagIcon(code)}
      <span style={{ marginLeft: '0.7em' }}>{_language === 'ko' ? _country['country_kr'] : _country['country']}</span>
    </span>
  );
};

/**
 * 국가번호
 * @param {string} code       - 국가코드
 * @returns {string} 국가번호
 */
export const getCountryCallingCode = code => {
  const _countryList = useCountry.getState().getCountryList();
  return _countryList.find(c => c['country_code2'] === code)['phone_code']
    ? `+${_countryList.find(c => c['country_code2'] === code)['phone_code']}`
    : '';
};

/**
 * 파일타입 영상인지 확인
 * @param {string} type       - 파일 타입
 * @returns {boolean} true / false
 */
export const checkIsVideoFile = type => {
  return /(mov|mp4|braw|r3d|mxf|dng|mts|zip|jpg|jpeg|png|eps|psd|ai)/i.test(type);
};

/**
 * 파일목록의 모든 파일이 비디오타입인지 확인
 * @param {object[]} array
 * @returns {boolean} true / false
 */
export const checkIsAllVideoFile = array => {
  let rtn = true;
  if (_.isEmpty(array)) rtn = false;
  else {
    for (const item of array) {
      if (!checkIsVideoFile(item?.file_type)) {
        rtn = false;
        break;
      } else continue;
    }
  }
  return rtn;
};

/**
 * 파일목록의 모든 파일이 제출가능한 파일인지 확인
 * @param {object[]} array
 * @returns {boolean} true / false
 */
export const checkIsPossibleToSubmit = array => {
  let rtn = true;
  if (_.isEmpty(array)) rtn = false;
  else {
    for (const item of array) {
      if (!_.isEmpty(item?.isPossibleUpload) || !item?.isPossibleUpload || item.keywords?.split(',').length < 7) {
        rtn = false;
        break;
      } else continue;
    }
  }
  return rtn;
};

/**
 * 바이트 -> kb/mb 변환
 * @param {number} size     - 바이트
 *
 * @returns {string} kb/mb (단위포함)
 */
export const byteConverter = (size = 0) => {
  const unit = size > 1048576 ? 'MB' : 'KB';
  const volume = size > 1048576 ? Math.floor(size / 1024 / 1024) : Math.floor(size / 1024);
  return `${addComma(volume)}${unit}`;
};

/**
 * 키컷스톡 작가 페이지 링크
 * @param {string} str      - 프로필 이름
 *
 * @returns {string} 링크
 */
export const getCreatorUrl = str => {
  return str
    .replace(/&.+?;/g, '')
    .replace(/[^가-힣\w\d \._-]/g, '')
    .replace(/\s+/g, '+')
    .toLowerCase();
};

/**
 * 포맷된 날짜로 변환
 * - 한국어 : YYYY/MM/DD
 * - 영어 : MM/DD/YYYY
 * @param {number} date
 * @returns {string} date
 */
export const getFormattedDate = date => {
  const language = useLanguage.getState().getLanguage();
  if (!date) return '';
  else return moment(date).format(language === 'ko' ? 'YYYY/MM/DD' : 'MM/DD/YYYY');
};
