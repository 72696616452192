import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { shallow } from 'zustand/shallow';

import useLanguagePack from '../../hooks/useLanguagePack';
import useLanguage from '../../store/language';
import { fontFamily } from '../common/typography/Typography';
import { CDN_URL } from '../../constants';

const MainPopup = ({ data, onClose, handleHidden }) => {
  const { language } = useLanguage(
    state => ({
      language: state.language,
    }),
    shallow
  );

  const languageKeyStrings = {
    buttonLabel1: useLanguagePack('popupnotice_label_1'),
    buttonLabel2: useLanguagePack('popupnotice_label_2'),
  };

  const clickable = useMemo(() => !_.isEmpty(data?.[`link_url_${language}`]), [data, language]);

  const onClickEvt = useCallback(
    path => {
      if (clickable) window.open(path, '_blank');
    },
    [clickable]
  );

  return (
    <Modal>
      <ModalContents clickable={clickable} onClick={() => onClickEvt(data[`link_url_${language}`])}>
        <img src={`${CDN_URL}/${data[`content_url_${language}`]}`} alt={'popup'} />
      </ModalContents>
      <ModalActions>
        <ModalTextButton onClick={handleHidden}>{languageKeyStrings.buttonLabel1}</ModalTextButton>
        <ModalTextButton onClick={onClose}>{languageKeyStrings.buttonLabel2}</ModalTextButton>
      </ModalActions>
    </Modal>
  );
};

export default MainPopup;

export const PopupContainer = styled.div.attrs(props => ({ role: 'presentation' }))`
  position: fixed;
  top: ${({ theme }) => theme.spacing(27)};
  left: ${({ theme }) => theme.spacing(25)};
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(10)};
`;

const Modal = styled.div`
  object-fit: contain;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);
`;

const ModalContents = styled.div`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  & > img {
    display: block;
    max-height: 675px;
  }
`;

const ModalActions = styled.div`
  background-color: #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(3)};
`;

const ModalTextButton = styled.p.attrs(props => ({ role: 'button' }))`
  ${fontFamily};
  margin: 0;
  color: #555;
  font-size: ${({ theme }) => theme.fontSize['12']};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
`;
